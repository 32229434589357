var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "news"
  }, [_c('img', {
    staticClass: "news-bg-left",
    attrs: {
      "src": require('@/assets/event/caves-education-authorize/news-boy.png')
    }
  }), _c('div', {
    staticClass: "text-center data-scroll",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('picture', [_c('source', {
    attrs: {
      "srcset": require('@/assets/event/caves-education-authorize/news-title-xl.png'),
      "media": "(min-width:1920px)"
    }
  }), _c('source', {
    attrs: {
      "srcset": require('@/assets/event/caves-education-authorize/news-title-md.png'),
      "media": "(min-width:1240px)"
    }
  }), _c('source', {
    attrs: {
      "srcset": require('@/assets/event/caves-education-authorize/news-title-sm.png'),
      "media": "(min-width:768px)"
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/caves-education-authorize/news-title.png')
    }
  })])]), _c('div', {
    staticClass: "news-content data-scroll",
    attrs: {
      "data-scroll": ""
    }
  }, [_c('picture', [_c('source', {
    attrs: {
      "srcset": require('@/assets/event/caves-education-authorize/news-pic-xl.png'),
      "media": "(min-width:768px)"
    }
  }), _c('img', {
    attrs: {
      "src": require('@/assets/event/caves-education-authorize/news-pic-xl.png')
    }
  })]), _c('router-link', {
    attrs: {
      "to": {
        name: 'collaboration-with-caves-education'
      }
    }
  }, [_c('p', [_vm._v("了解更多詳細報導")]), _c('div', [_c('img', {
    attrs: {
      "src": require('@/assets/event/caves-education-authorize/arrow.png')
    }
  })])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }