<template>
  <div v-show="galleryShow" class="galleryModal">
    <div class="gallery">
      <span class="gallery-close" @click="$emit('update:galleryShow', false)">
        <!-- close -->
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z"
            fill="white"
          />
        </svg>
        <!-- close -->
      </span>

      <!-- 幻燈片 -->
      <div class="gallery-pic">
        <img :src="gallery.active" alt="book1">
      </div>

      <!-- Next/previous controls -->
      <span class="prev" @click="sliderPrev(gallery.picIndex - 1)">&#10094;</span>
      <span class="next" @click="sliderNext(gallery.picIndex + 1)">&#10095;</span>
      <div class="thumb-row">
        <div v-for="item in gallery.list" :key="item.index" :class="['thumb', {'active': gallery.picIndex === item.index}]" @click="gallery.picIndex = item.index">
          <img :src="item.src" :alt="`book${item.index}`">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GalleryModal',
  props: {
    galleryShow: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    gallery: {
      active: require('@/assets/event/caves-education-authorize/cross-industry/book1-active.png'),
      isOpen: false,
      picIndex: 0,
      list: [
        {
          index: 0,
          src: require('@/assets/event/caves-education-authorize/cross-industry/book1.png'),
          active: require('@/assets/event/caves-education-authorize/cross-industry/book1-active.png'),
        },
        {
          index: 1,
          src: require('@/assets/event/caves-education-authorize/cross-industry/book2.png'),
          active: require('@/assets/event/caves-education-authorize/cross-industry/book2-active.png'),
        },
        {
          index: 2,
          src: require('@/assets/event/caves-education-authorize/cross-industry/book3.png'),
          active: require('@/assets/event/caves-education-authorize/cross-industry/book3-active.png'),
        },
        {
          index: 3,
          src: require('@/assets/event/caves-education-authorize/cross-industry/book4.png'),
          active: require('@/assets/event/caves-education-authorize/cross-industry/book4-active.png'),
        },
        {
          index: 4,
          src: require('@/assets/event/caves-education-authorize/cross-industry/book5.png'),
          active: require('@/assets/event/caves-education-authorize/cross-industry/book5-active.png'),
        },
      ],
    },
  }),
  watch: {
    galleryShow: function(n, o) {
      if (n) {
        this.gallery.picIndex = 0;
        this.lockBody();
        return;
      }
      this.resetLockBody();
    },
    'gallery.picIndex': function(n, o) {
      this.gallery.active = this.gallery.list[n].active;
    },
  },
  methods: {
    sliderPrev(index) {
      if (index < 0) {
        index = 4;
      }
      this.gallery.picIndex = index;
    },

    sliderNext(index) {
      if (index > 4) {
        index = 0;
      }
      this.gallery.picIndex = index;
    },
    lockBody() {
      const body = document.querySelector('body');
      body.style.overflow = 'hidden';
      body.style.width = '100vw';
      body.style.height = '100vh';
    },
    resetLockBody() {
      const body = document.querySelector('body');
      body.removeAttribute('style');
    },
  },
};
</script>

<style lang="scss" scoped>
.galleryModal {
  position: fixed;
  z-index: 1030;
  // left: 0;
  // top: 0;
  inset: 0;
  // width: 100vw;
  height: 100%;
  background-color: #4f4f4fe6;
  background-image: radial-gradient(circle, #ffffff00 50%, #4c4c4c 70%);
  backdrop-filter: blur(10px);
}
.gallery {
  height: 100%;
  position: relative;
  padding: 20px;
  .prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: #FFA756;
    font-weight: bold;
    font-size: 32px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    transition: background-color 0.35s ease-out, color 0.25s ease-in;
  }
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    color: #FFA756;
    font-weight: bold;
    font-size: 32px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    transition: background-color 0.35s ease-out, color 0.25s ease-in;
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  .thumb {
    cursor: pointer;
    margin: 0 5px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background-color: #000;
    &.active {
      border: 1px solid #75CDEC;
    }
  }
}
.gallery-close {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
}
.gallery-pic {
  height: calc(100% - 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    max-width: 100%;
  }
}
.thumb-row {
  margin: 20px auto 0 auto;
  max-width: 1120px;
  padding-bottom: 20px;
  display: flex;
  overflow-x: scroll;
}
</style>
