<template lang="pug">
.news
  img(class="news-bg-left" :src="require('@/assets/event/caves-education-authorize/news-boy.png')")
  div(data-scroll class="text-center data-scroll")
    picture
      source(:srcset="require('@/assets/event/caves-education-authorize/news-title-xl.png')" media="(min-width:1920px)")
      source(:srcset="require('@/assets/event/caves-education-authorize/news-title-md.png')" media="(min-width:1240px)")
      source(:srcset="require('@/assets/event/caves-education-authorize/news-title-sm.png')" media="(min-width:768px)")
      img(:src="require('@/assets/event/caves-education-authorize/news-title.png')")
  .news-content(data-scroll class="data-scroll")
    picture
      source(:srcset="require('@/assets/event/caves-education-authorize/news-pic-xl.png')" media="(min-width:768px)")
      img(:src="require('@/assets/event/caves-education-authorize/news-pic-xl.png')")
    router-link( :to="{ name: 'collaboration-with-caves-education' }")
      p 了解更多詳細報導
      div
        img(:src="require('@/assets/event/caves-education-authorize/arrow.png')")
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
    };
  },

  methods: {
  },
};
</script>
<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1920px) {
    @content;
  }
}

.news{
  padding: 140px 0 160px;
  background: url("~@/assets/event/caves-education-authorize/news-bg.png") , #fffceb;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @include md {
    padding: 140px 0 180px;
  }
  @include xl{
    padding: 220px 0 240px;
  }
  @include xxl{
    padding: 300px 0 240px;
  }
  .news-bg-left{
    position: absolute;
    left: 0;
    bottom:0;
    width: 180px;
    @include md{
      width: 240px;
    }
    @include xl{
      width: 330px;
    }
    @include xxl{
      width: 475px;
    }
  }
  &-content{
    text-align: center;
    margin-top: 80px ;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    img{
      max-width: 100%;
    }
    padding: 0 16px;
    @include md {
      padding: 0 40px;
    }
    @include xxl{
      margin-bottom: 5vw;
    }
    a{
      display: flex;
      gap: 10px;
      justify-content: end;
      align-items: center;
      background: transparent;
      margin-left: auto;
      margin-top:20px;
      margin-bottom: 40px;

      cursor: pointer;
      &:hover{
        >div{
          background: #288ACB;
        }
      }
      >div{
        width: 30px;
        aspect-ratio: 1 / 1;
        background: #88c2e8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        @include md{
          width: 50px;
        }
      }
      p{
        color: #625B4F;
        font-weight: 900;
        line-height: 120%;
        font-size: 14px;
        @include md{
          font-size: 18px;
        }
      }
    }
  }
}
</style>
