<template lang="pug">
.satisfaction
  .satisfaction-container
    .satisfaction-title(data-scroll class="text-center data-scroll")
      picture
        source(:srcset="require('@/assets/event/caves-education-authorize/satisfaction-title-xl.png')" media="(min-width:768px)")
        img(:src="require('@/assets/event/caves-education-authorize/satisfaction-title-sm.png')")

    div(data-scroll class="data-scroll")
      div( class="swiper-pc")
        swiper(:options="swiperOption" )
          swiper-slide(v-for="(group , index) in feedBackData")
            div(class="feedback-grid")
              div(v-for="(feedback , index) in group"  :key="index" class="feedback-card")
                div(class="feedback-box")
                  div
                    img(:src="feedback.image")
                  div
                    h3 {{ feedback.vip }}
                    p {{ feedback.content }}
          <div class="swiper-button-prev swiper-button-prev-pc" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-next-pc" slot="button-next"></div>
        <div class="swiper-pagination swiper-pagination-pc" ></div>

      div(class="swiper-tablet")
        swiper(:options="swiperOptionTablet")
          swiper-slide(v-for="(feedback , index) in feedBackDataPhone" class="feedback-card")
            div(class="feedback-box")
              div
                img(:src="feedback.image")
              div
                h3 {{ feedback.vip }}
                p {{ feedback.content }}
          <div class="swiper-pagination swiper-pagination-tablet text-center" slot="pagination"></div>
          <div class="swiper-button-prev swiper-button-prev-tablet" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-next-tablet" slot="button-next"></div>
      div(class="swiper-phone")
        swiper(:options="swiperOptionPhone")
          swiper-slide(v-for="(feedback , index) in feedBackDataPhone" class="feedback-card")
            div(class="feedback-box")
              div
                img(:src="feedback.image")
              div
                h3 {{ feedback.vip }}
                p {{ feedback.content }}
          <div class="swiper-pagination swiper-pagination-phone text-center" slot="pagination"></div>
          <div class="swiper-button-prev swiper-button-prev-phone" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-next-phone" slot="button-next"></div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      feedBackData: [
        [
          {
            'content': '教材更改後，上課變得更多元、有趣，連陪同上課的我，也忍不住想參與整場課程！',
            'vip': 'Emily 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback1.png',
          },
          {
            'content': '很喜歡現在教材除了一般英文學習外，還有不同主題的其他學習，讓我幫孩子課後複習可以有更多的延伸！',
            'vip': 'Ethan 爸爸',
            'image': 'https://cdn3.wuwow.tw/arts/feedback2.png',
          },
          {
            'content': '本來只是想讓他多一個學英文的管道，沒想到Isabella上了幾堂之後很喜歡，沒事就拉著我講英文。',
            'vip': 'Isabella 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback3.png',
          },
          {
            'content': '從連ABC都不知道學到現在已經會一些簡單單字，新教材圖片生動活潑。',
            'vip': 'Olivia 爸爸',
            'image': 'https://cdn3.wuwow.tw/arts/feedback4.png',
          },
          {
            'content': 'Alice從以前的三催四請，現在上課時間一到就會乖乖就定位，也更願意開口說英文～',
            'vip': 'Alice 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback5.png',
          },
          {
            'content': '專業師資加上特別編排的課程教材，是我在搜集、試聽很多家線上英文課程後，為孩子選擇WUWOW Jr.最主要的原因。',
            'vip': 'Noah 爸爸',
            'image': 'https://cdn3.wuwow.tw/arts/feedback6.png',
          },
        ],
        [
          {
            'content': '改版後讓我最滿意的地方是增加了音檔，可以針對孩子比較弱的部分特別加強。',
            'vip': 'Sophia 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback7.png',
          },
          {
            'content': '很感謝Jr老師一直很有耐心引導，上課有問題的話客服也很積極的協助。',
            'vip': 'Charlotte 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback8.png',
          },
          {
            'content': '補習班上課時間固定，以前都花很多時間安排接送，轉到WUWOW Jr.的線上課程後時間彈性，效果也沒有打折！',
            'vip': 'Mia 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback9.png',
          },
          {
            'content': '身為一個二寶媽＋國小老師，小朋友喜不喜歡一個東西其實很明顯，一看就知道我們家小孩是真的喜歡在這邊上課。',
            'vip': 'Eva 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback10.png',
          },
          {
            'content': '老師經驗豐富，在我們家小孩注意力渙散的時候，都會馬上拉回來。',
            'vip': 'Toby 媽媽',
            'image': 'https://cdn3.wuwow.tw/arts/feedback11.png',
          },
          {
            'content': '每堂課都有進度追蹤，讓我跟媽媽都蠻放心的。',
            'vip': 'Liam 爸爸',
            'image': 'https://cdn3.wuwow.tw/arts/feedback12.png',
          },
        ],
      ],
      feedBackDataPhone: [{
                            content: '教材更改後，上課變得更多元、有趣，連陪同上課的我，也忍不住想參與整場課程！',
                            vip: 'Emily 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback1.png',
                          },
                          {
                            content: '很喜歡現在教材除了一般英文學習外，還有不同主題的其他學習，讓我幫孩子課後複習可以有更多的延伸！',
                            vip: 'Ethan 爸爸',
                            image: 'https://cdn3.wuwow.tw/arts/feedback2.png',
                          },
                          {
                            content: '本來只是想讓他多一個學英文的管道，沒想到Isabella上了幾堂之後很喜歡，沒事就拉著我講英文。',
                            vip: 'Isabella 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback3.png',
                          },
                          {
                            content: '從連ABC都不知道學到現在已經會一些簡單單字，新教材圖片生動活潑。',
                            vip: 'Olivia 爸爸',
                            image: 'https://cdn3.wuwow.tw/arts/feedback4.png',
                          },
                          {
                            content: 'Alice從以前的三催四請，現在上課時間一到就會乖乖就定位，也更願意開口說英文～',
                            vip: 'Alice 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback5.png',
                          },
                          {
                            content: '專業師資加上特別編排的課程教材，是我在搜集、試聽很多家線上英文課程後，為孩子選擇WUWOW Jr.最主要的原因。',
                            vip: 'Noah 爸爸',
                            image: 'https://cdn3.wuwow.tw/arts/feedback6.png',
                          },
                          {
                            content: '改版後讓我最滿意的地方是增加了音檔，可以針對孩子比較弱的部分特別加強。',
                            vip: 'Sophia 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback7.png',
                          },
                          {
                            content: '很感謝Jr老師一直很有耐心引導，上課有問題的話客服也很積極的協助。',
                            vip: 'Charlotte 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback8.png',
                          },
                          {
                            content: '補習班上課時間固定，以前都花很多時間安排接送，轉到WUWOW Jr.的線上課程後時間彈性，效果也沒有打折！',
                            vip: 'Mia 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback9.png',
                          },
                          {
                            content: '身為一個二寶媽＋國小老師，小朋友喜不喜歡一個東西其實很明顯，一看就知道我們家小孩是真的喜歡在這邊上課。',
                            vip: 'Eva 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback10.png',
                          },
                          {
                            content: '老師經驗豐富，在我們家小孩注意力渙散的時候，都會馬上拉回來。',
                            vip: 'Toby 媽媽',
                            image: 'https://cdn3.wuwow.tw/arts/feedback11.png',
                          },
                          {
                            content: '每堂課都有進度追蹤，讓我跟媽媽都蠻放心的。',
                            vip: 'Liam 爸爸',
                            image: 'https://cdn3.wuwow.tw/arts/feedback12.png',
                          },
      ],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 100,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-pc',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-pc',
          prevEl: '.swiper-button-prev-pc',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },
      swiperOptionTablet: {
        slidesPerView: 3,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-tablet',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-tablet',
          prevEl: '.swiper-button-prev-tablet',
        },
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 5000,
        // },
      },
      swiperOptionPhone: {
        slidesPerView: 1.5,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        speed: 1000,
        pagination: {
          el: '.swiper-pagination-phone',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next-phone',
          prevEl: '.swiper-button-prev-phone',
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },

    };
  },

  methods: {
  },
};
</script>
<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: 1920px) {
    @content;
  }
}

.satisfaction {
  background: url("~@/assets/event/caves-education-authorize/satisfaction-bg-m.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -220px;
  padding-top: 240px;
  padding-bottom: 155px;
  @include md {
    margin-top: -240px;
    padding-top: 262px;
    padding-bottom: 155px;
  }

  @include xl {
    background: url("~@/assets/event/caves-education-authorize/satisfaction-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -285px;
    padding-top: 262px;
    padding-bottom: 180px;
  }

  @include xxl {
    margin-top: -15vw;
    padding-top: 15vw;
    padding-bottom: 10.67vw;
  }

  position: relative;
  z-index: 1;

  &-container {
    max-width: 1180px;
    margin: 0 auto;

    @include md {
      padding: 0 16px;
    }

    @include xl {
      padding: 0 40px;
    }
  }
  &-title{
    margin-bottom: 80px;
    @include xl{
      margin-bottom: 32px;
    }
  }
}

.swiper-pc {
  display: none;
  position: relative;

  @include xl {
    display: block;
  }

  .feedback-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .feedback-card {

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      transform: translateX(-40px);
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      transform: translateX(40px);
    }

  }
}

.feedback-box {
  padding: 60px 24px 30px;
  background: #FFFEFA;
  border-radius: 40px;
  border: 2px solid #75CDEC;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }

  h3 {
    color: #75CDEC;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.3;
    text-align: center;
  }

  p {
    color: var(#625B4F);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
  }

  @include xl {
    display: flex;
    gap: 20px;
    padding: 24px 28px;
    img {
      position: static;
      left: unset;
      top: unset;
      transform: translate(0%, 0%);
    }
    h3{
      text-align: left;
    }
  }
}

.swiper-tablet {
  display: none;

  @media (min-width: 768px) and (max-width: 1240px) {
    display: block;
  }
}

.swiper-phone {
  display: block;

  @include md {
    display: none;
  }
}</style>
