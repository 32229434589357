<template lang="pug">
main()
  .header(:class ="{ 'active' : headerActive}")
    .container
      //- JR logo
      router-link.jr-logo(:to="{name:'home'}")
        img(:src="require('@/assets/event/trial/jr-logo.svg')", alt="WUWOW JR Logo")
  .form-upper
    .title-area
      .main-title-phone(data-scroll class="data-scroll")
        picture
          source(:srcset="require('@/assets/event/caves-education-authorize/banner-title.png')" media="(min-width:1920px)")
          source(:srcset="require('@/assets/event/caves-education-authorize/banner-title-xl.png')" media="(min-width:1240px)")
          img(:src="require('@/assets/event/caves-education-authorize/banner-title-sm.png')")
    //- img.form-visual.mobile(:src="require('@/assets/event/trial/form-visual-mobile.png')", alt="表單主視覺")
    //- img.form-visual.tablet(:src="require('@/assets/event/trial/form-visual-tablet.png')", alt="表單主視覺")
    .form-visual
      .visual-background
        div(class=" position-relative")
          img(:src="require('@/assets/event/caves-education-authorize/landing-major.png')", alt="表單主視覺背景")
          img.shine(:src="require('@/assets/event/caves-education-authorize/shine-bg.png')", alt="旋轉")
      img.spark.spark-small(:src="require('@/assets/event/caves-education-authorize/spark-small.png')", alt="星型裝飾")
      img.spark.spark-small2(:src="require('@/assets/event/caves-education-authorize/spark-small.png')", alt="星型裝飾")
      img.spark.spark-medium(:src="require('@/assets/event/caves-education-authorize/spark-medium.png')", alt="星型裝飾")
      img.spark.spark-large(:src="require('@/assets/event/caves-education-authorize/spark-large.png')", alt="星型裝飾")

    .form-area
      .title-area
        .main-title(data-scroll class="data-scroll")
          picture
            source(:srcset="require('@/assets/event/caves-education-authorize/banner-title.png')" media="(min-width:1920px)")
            source(:srcset="require('@/assets/event/caves-education-authorize/banner-title-xl.png')" media="(min-width:1240px)")
            img(:src="require('@/assets/event/caves-education-authorize/banner-title-sm.png')")
      .form-box
        .form-card(data-scroll)
          .form-input
            .icon: img(:src="require('@/assets/event/trial/member-orange.svg')")
            input(v-model="formUpper.parentName",type="text",placeholder="家長姓名（必填）")

          .form-input
            .icon: img(:src="require('@/assets/event/trial/phone-orange.svg')")
            input(v-model="formUpper.parentPhone",type="text",placeholder="家長電話（必填）")
          .form-input
            .icon: img(,:src="require('@/assets/event/trial/mail-orange.svg')")
            input(v-model="formUpper.parentEmail",type="text",placeholder="家長email（必填）")

          .form-privacy
            input#privacy-upper(v-model="formUpper.privacyPolicyCheck",type="checkbox")
            label(for="privacy-upper")
              img(:src="require('@/assets/event/trial/check.svg')")
            span 我同意 WUWOW 線上英文&ensp;
              router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策

          button.form-btn(@click="submitForm(formUpper)") 免費搶先預約

          .form-divider
            img(:src="require('@/assets/event/trial/divider-left.svg')" style="object-fit: contain;")
            span 兒童線上英文
            img(:src="require('@/assets/event/trial/divider-right.svg')" style="object-fit: contain;")

          .gift-area.mb-2.mb-xl-4
            button.gift.w-100.ml-0(@click="openAbilitiesUpbringingModal()")
              span
              span
              span
              span
              img(:src="require('@/assets/event/trial/gift.svg')")
              |  家教課1堂＋英文能力報告
          .gift-area
            button.gift.w-100.ml-0(type="button" @click="galleryShow = true")
              span
              span
              span
              span
              img(:src="require('@/assets/event/trial/gift.svg')")
              | 敦煌專屬兒美單字書 (附音檔)

  .features
    .feature-plan
      picture
        source(:srcset="require('@/assets/event/caves-education-authorize/feature-plan.png')" media="(min-width:1240px)")
        img(:src="require('@/assets/event/caves-education-authorize/feature-plan-sm.png')")
    .features-background
      .kid
        img(:src="require('@/assets/event/caves-education-authorize/features-background-kid.png')")
      .features-container
        .features-title.text-center(data-scroll class="data-scroll")
          picture
            source(:srcset="require('@/assets/event/caves-education-authorize/feature-title-xl.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:1920px)")
            source(:srcset="require('@/assets/event/caves-education-authorize/feature-title-sm.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:768px)")
            img(:src="require('@/assets/event/caves-education-authorize/feature-title.png')" alt="WUWOW JUNIOR 四大特色")
        ul.features-main
          li(data-scroll)
            .features-card
              h1.features-number(data-storke="1") 1
              div.features-pc
                h3(data-storke="敦煌書局" style=" color:#FFCE21") 敦煌書局
                h3(data-storke="首家授權兒美教材" style=" color:#FFCE21") 首家授權兒美教材
              div
                img(:src="require('@/assets/event/caves-education-authorize/feature-card1.png')")
              .features-content
                .features-phone
                  h3(data-storke="敦煌書局" style=" color:#FFCE21") 敦煌書局
                  h3(data-storke="首家授權兒美教材" style=" color:#FFCE21") 首家授權兒美教材
                p 教材多元豐富，課後附英檢題型、音檔，學英文同時跨領域培養 21世紀能力、素養。
                .text-center
                  button.btn.btn-xl.features-order(@click="scrollSection('.features-banner')", type="button") 瞭解更多
          li(data-scroll)
            .features-card
              h1.features-number(data-storke="2") 2
              div.features-pc
                h3(data-storke="25分鐘 1對1家教" style=" color:#EB505A") 25分鐘 1對1家教
              div
                img(:src="require('@/assets/event/caves-education-authorize/feature-card2.png')")
              .features-content
                .features-phone
                  h3(data-storke="25分鐘 1對1家教" style=" color:#EB505A") 25分鐘 1對1家教
                p 短時間專注學習 加 充分開口機會，家教型 VIP 精緻學習，寶貝進步超有感！
          li(data-scroll)
            .features-card
              h1.features-number(data-storke="3") 3
              div.features-pc
                h3(data-storke="嚴選師資 汰弱留強" style=" color:#71CA2B") 嚴選師資 汰弱留強
              div
                img(:src="require('@/assets/event/caves-education-authorize/feature-card3.png')")
              .features-content
                .features-phone
                  h3(data-storke="嚴選師資 汰弱留強" style=" color:#71CA2B") 嚴選師資 汰弱留強
                p 教師均有兒美教學經驗及國際教學證照，並定期監督、培訓，維持最佳品質！
          li(data-scroll)
            .features-card
              h1.features-number(data-storke="4") 4
              div.features-pc
                h3(data-storke="分級完整 課綱明確" style=" color:#FFCE21") 分級完整 課綱明確
              div
                img(:src="require('@/assets/event/caves-education-authorize/feature-card4.png')")
              .features-content
                .features-phone
                  h3(data-storke="分級完整 課綱明確" style=" color:#FFCE21") 分級完整 課綱明確
                p 對標國際分為12等級，以劍橋兒童英檢作為教學課綱，循序漸進系統化學習。
                .text-center
                  button.btn.btn-xl.features-order(@click="scrollSection('.features-syllabus')", type="button") 瞭解更多
    .features-bannerContainer
      .features-banner
        .features-kid
          picture
            source(:src="require('@/assets/event/caves-education-authorize/material-kid-xl.png')"  media="(min-width:1920px)")
            source(:srcset="require('@/assets/event/caves-education-authorize/material-kid-md.png')"  media="(min-width:768px)")
            img(:src="require('@/assets/event/caves-education-authorize/material-kid-sm.png')" )
        .title(data-scroll class="text-center data-scroll")
          picture
            source(:srcset="require('@/assets/event/caves-education-authorize/material-title-xl.png')" alt="首家 敦煌教育 授權教材" media="(min-width:1920px)")
            source(:srcset="require('@/assets/event/caves-education-authorize/material-title-sm.png')" alt="首家 敦煌教育 授權教材" media="(min-width:768px)")
            img(:src="require('@/assets/event/caves-education-authorize/material-title.png')" alt="首家 敦煌教育 授權教材" style="max-width:100%")
        .material-container
          .material-card(data-scroll)
            picture
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card1.png')"  media="(min-width:1920px)")
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card1.png')"  media="(min-width:576px)")
              img(:src="require('@/assets/event/caves-education-authorize/material-card1-m.png')" )
          .material-card(data-scroll)
            picture
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card2.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:1920px)")
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card2.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:576px)")
              img(:src="require('@/assets/event/caves-education-authorize/material-card2-m.png')" alt="WUWOW JUNIOR 四大特色")
          .material-card(data-scroll)
            picture
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card3.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:1920px)")
              source(:srcset="require('@/assets/event/caves-education-authorize/material-card3.png')" alt="WUWOW JUNIOR 四大特色" media="(min-width:576px)")
              img(:src="require('@/assets/event/caves-education-authorize/material-card3-m.png')" alt="WUWOW JUNIOR 四大特色")
        div.material-line
        .features-arrows(data-scroll)
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          label.features-more(@click="toggleCheckMore" for="btn-check" :class="{'active': isCheckedMore}") 點我看更多
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")
          img(:src="require('@/assets/event/trial/learnReason-arrow.png')")

        //- input.btn-check.d-none(type="checkbox" id="btn-check" autocomplete="off")
        .features-table(v-show="isCheckedMore")
          .row.align-items-center.d-
            .col-12.col-xl-6.mb-5.mb-xl-0
              .d-flex.justify-content-center.dunhuang
                img(:src="require('@/assets/material_2023/Dunhuang.svg')")
                img.mx-4(:src="require('@/assets/material_2023/close.svg')")
                img(:src="require('@/assets/material_2023/Caves.svg')")
              p.p-1(data-scroll) 與深耕70年外語教育的敦煌教育集團合作，選用 CAVES KIDS 教材，主題內容多元豐富，涵蓋歷史、科技、藝術...，課後提供仿照劍橋兒童英檢題型及練習音檔，按部就班紮實學英文，同時跨領域培養孩子們 21世紀所需的知識能力與素養。
            .col-12.col-xl-6
              img.w-100.cursor-pointer.dunhuang-img(data-scroll)(@click="youtubeModal(materialVideo)" :src="require('@/assets/material_2023/video.svg')")
          .bookcaseWrap
            .bookcase-first(data-scroll)
              h3
              ul(@click="openMaterialModal('modalContentTwo')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book1.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book2.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book3.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book4.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book5.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book6.jpg')")
            .bookcase-two(data-scroll)
              h3
              ul(@click="openMaterialModal('modalContentOne')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book2-1.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book2-2.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book2-3.jpg')")
                li.cursor-pointer
                  img(:src="require('@/assets/material_2023/book2-4.jpg')")

      .features-syllabus-container
        .text-center(data-scroll class="data-scroll features-syllabus-title")
          picture
            source(:srcset="require('@/assets/event/caves-education-authorize/syllabus-title-xl.png')" alt="分級完整 課綱明確" media="(min-width:1920px)")
            source(:srcset="require('@/assets/event/caves-education-authorize/syllabus-title-sm.png')" alt="分級完整 課綱明確" media="(min-width:768px)")
            img(:src="require('@/assets/event/caves-education-authorize/syllabus-title.png')" alt="分級完整 課綱明確")
        .features-syllabus
          div(data-scroll class="data-scroll position-relative text-center " style="z-index:2")
            picture
              source(:srcset="require('@/assets/event/caves-education-authorize/syllabus-boy-xl.png')" alt="快樂學習" media="(min-width:1920px)")
              source(:srcset="require('@/assets/event/caves-education-authorize/syllabus-boy-sm.png')" alt="快樂學習" media="(min-width:768px)")
              img(:src="require('@/assets/event/caves-education-authorize/syllabus-boy.png')" alt="快樂學習")

          div
            .syllabus-level
              .level-card(data-scroll)
                img(class="level-img" :src="require('@/assets/event/caves-education-authorize/syllabus-level1.png')")
                .level-content
                  h3 12等級 對標國際
                  p 明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著
                .level-line
                  img(:src="require('@/assets/event/caves-education-authorize/syllabus-level1-line.png')")
              .level-card(data-scroll)
                img(class="level-img" :src="require('@/assets/event/caves-education-authorize/syllabus-level2.png')")
                .level-content
                  h3 融合劍橋英檢與108課綱<br>打造Jr. 新課綱
                  p 明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著
                .level-line
                  img(:src="require('@/assets/event/caves-education-authorize/syllabus-level2-line.png')")
              .level-card(data-scroll)
                img(class="level-img" :src="require('@/assets/event/caves-education-authorize/syllabus-level3.png')")
                .level-content
                  h3 0基礎也不擔心
                  p 明列12等級各階段學習目標明確，聽、説、讀、寫全方位學習成效更顯著
                .level-line
                  img(:src="require('@/assets/event/caves-education-authorize/syllabus-level3-line.png')")
          .gradient-ball
  news
  satisfaction
  .form-lower
    .form-area
      img.form-title.mobile(:src="require('@/assets/event/caves-education-authorize/form-lower-title-mobile.png')")
      img.form-title.tablet(:src="require('@/assets/event/caves-education-authorize/form-lower-title-tablet.png')")
      img.form-title.desktop(:src="require('@/assets/event/caves-education-authorize/form-lower-title-desktop.png')")
      .form-card
        .form-input
          .icon: img(:src="require('@/assets/event/trial/member-blue.svg')")
          input(v-model="formLower.parentName",type="text",placeholder="家長姓名（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/phone-blue.svg')")
          input(v-model="formLower.parentPhone",type="text",placeholder="家長電話（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/mail-blue.svg')")
          input(v-model="formLower.parentEmail",type="text",placeholder="家長email（必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-blue.svg')")
          input(v-model="formLower.studentChineseName",type="text",placeholder="孩子中文姓名（非必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-a-blue.svg')")
          input(v-model="formLower.studentEnglishName",type="text",placeholder="小孩英文名稱（非必填）")
        .form-input
          .icon: img(:src="require('@/assets/event/trial/kid-five-blue.svg')")
          input(v-model="formLower.studentAge",type="text",placeholder="孩子年齡（非必填）")

        .form-privacy
          input#privacy-lower(v-model="formLower.privacyPolicyCheck",type="checkbox")
          label(for="privacy-lower")
            img(:src="require('@/assets/event/trial/check.svg')")
          span 我同意 WUWOW 線上英文&ensp;
            router-link(:to="{path:privacyUrl}",target="_blank") 隱私權政策

        .form-divider
          img(:src="require('@/assets/event/trial/divider-left.svg')")
          span 兒童線上英文
          img(:src="require('@/assets/event/trial/divider-right.svg')")

        .gift-area.mb-2.mb-xl-4
          button.gift.w-100.ml-0(@click="openAbilitiesUpbringingModal()")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            |  家教課1堂＋英文能力報告
        .gift-area
          button.gift.w-100.ml-0(type="button" @click="galleryShow = true")
            span
            span
            span
            span
            img(:src="require('@/assets/event/trial/gift.svg')")
            | 敦煌專屬兒美單字書 (附音檔)

        button.form-btn(@click="submitForm(formLower)") 免費搶先預約

  //- 移植JR首頁，footer
  .lioshutan-footer.bg-lioshutan.py-4
    //- 手機用
    .d-block.d-md-none.py-4
      .text-center
        a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
          h5.mb-4.text-light 合作提案
        a.text-decoration-none(:href="joinUsUrl" target="_blank")
          h5.mb-4.text-light 夥伴招募
        a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
          h5.mb-4.text-light 成為老師
      .text-light.text-center.contact
        h5 聯絡我們
        a.text-light(href="tel:0800885315") Tel: 0800-885315
        a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
        .d-flex.mt-4.justify-content-center
          a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
            img(:src="require('@/assets/event/trial/icon-fb.svg')")
            p wuwow jr
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
      a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.py-5
        img.w-50.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
        h6 WUWOW
        h6 最有效的線上英文
      a(href="/").d-flex.flex-column.align-items-center.text-light
        img.w-50.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
        h6 WUWOW Junior
        h6 專為孩子設計的線上英文

    //- 給平板的尺寸
    .d-none.d-md-block.d-lg-none
      .container
        .row.py-4
          .col-sm-6
            .text-center
              a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
                h5.mb-4.text-light 合作提案
              a.text-decoration-none(:href="joinUsUrl" target="_blank")
                h5.mb-4.text-light 夥伴招募
              a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
                h5.mb-4.text-light 成為老師
            .text-light.text-center.contact
              h5 聯絡我們
              a.text-light(href="tel:0800885315") Tel: 0800-885315
              a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
              .d-flex.mt-4.justify-content-center
                a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
                  img(:src="require('@/assets/event/trial/icon-fb.svg')")
                  p wuwow jr
              //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
              //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
          .col-sm-6
            a(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light.mb-3
              img.w-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
              h6 WUWOW
              h6 最有效的線上英文
            a(href="/").d-flex.flex-column.align-items-center.text-light
              img.w-75.jr-logo.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
              h6 WUWOW Junior
              h6 專為孩子設計的線上英文
    //- 電腦用
    .d-none.d-md-none.d-lg-block.container
      .d-flex.flex-row.justify-content-around.py-5
        div
          a.text-decoration-none(:href="`mailto:${cooperationUrl}`" target="_blank")
            h5.mb-4.text-light 合作提案
          a.text-decoration-none(:href="joinUsUrl" target="_blank")
            h5.mb-4.text-light 夥伴招募
          a.text-decoration-none(:href="pmRecruitmentUrl" target="_blank")
            h5.mb-4.text-light 成為老師
        .text-light.contact
          h5 聯絡我們
          a.text-light(href="tel:0800885315") Tel: 0800-885315
          a.text-light(href="mailto:service@wuwowjr.tw") service@wuwowjr.tw
          .d-flex.mt-4.justify-content-left
            a.mr-1.d-flex.flex-column.align-items-center.text-light(href="https://www.facebook.com/WUWOW.Junior" ,target="_blank")
              img(:src="require('@/assets/event/trial/icon-fb.svg')")
              p wuwow jr
          //-   img.mx-1(:src="file.lineSVG" width="50" height="50")
          //-   img.mx-1(:src="file.messengerSVG" width="50" height="50")
        a.w-25(href="https://www.wuwow.tw/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/arts/logos/wuwow-logo-multicolour-chinese.svg")
          h6 WUWOW
          h6 最有效的線上英文
        a.w-25(href="/").d-flex.flex-column.align-items-center.text-light
          img.w-75.h-75.mb-3(src="https://cdn2.wuwow.tw/images/wuwowjr-logo-font.svg")
          h6 WUWOW Junior
          h6 專為孩子設計的線上英文

  //- 滑動至表單
  button.scrollSection(@click="scrollTop()")
    img.init(:src="require('@/assets/event/caves-education-authorize/scroll-top.png')")
    img.hover(:src="require('@/assets/event/caves-education-authorize/scroll-top-hover.png')")

  transition()
    .docModal(v-if='doc.isOpen')
      span.doc-close(@click="closeAbilitiesUpbringingModal()")
        //-  close
        svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
        //-  close
      .d-flex.flex-column(v-if="doc.componentText === 'content'")
        .order-sm-1.order-2
          h3.doc-title {{doc.content.title}}
          h3.doc-subtitle {{doc.content.subTitle}}
          .doc-content
            img(:src='doc.content.imgPath' alt='')
        .order-sm-2.order-1(v-if="doc.contentTwo.open")
          h3.doc-title {{doc.contentTwo.title}}
          h3.doc-subtitle {{doc.contentTwo.subTitle}}
          .doc-content
            img(:src='doc.contentTwo.imgPath' alt='')
      .d-flex.justify-content-center.align-items-center(v-if="doc.componentText === 'video'")
        .order-sm-1.order-2
          h3.doc-title.mb-5 兒童英文繪本有聲書
          .videoWrap
            video(controls autoplay name="media" width="100%")
              source(src="https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book_trailer" type="video/mp4")

  transition()
    .reasonModal(v-if='material',@click.self='material=false')
      .material-content
        .material-dialog
          span.reason-close(@click='material=false')
            //-  close
            svg(width='30' height='30' viewbox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z' fill='white')
          template(v-if="materialContent === 'modalContentOne'")
            img(:src="require('@/assets/material_2023/modalContent-1.png')")
          template(v-else)
            img(:src="require('@/assets/material_2023/modalContent-2.svg')")

  youtube-modal(:src="youtubeUrl")
  gallery-modal(:gallery-show.sync="galleryShow")
</template>

<style lang="scss">
@import "@/views/event/cavesEducationAuthorize/style.scss";
</style>

<script>
import lioshutanApi from '@/api';
import { checkEmailFormat, checkPhoneFormat } from '@/utils/verify';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import { getEventMediaSource } from '@/utils/mediaSourse';
import httpCodeConstants from '@/constants/httpCode';
// for youtube modal
import { MODAL } from '@/store';
import { PAYLOAD } from '@/store/modules/modal';
import { IS_OPEN, SET_IS_OPEN, SET_PAYLOAD } from '@/store/modules/modal';
import { mapMutations, mapState } from 'vuex';
import galleryModal from './components/GalleryModal.vue';

import youtubeModal, { uuid as youtubeModalUUID } from '@/views/home/youtubeModal/index.vue';

import satisfaction from './components/satisfaction.vue';
import news from './components/news.vue';
// JR 首頁 footer版面用資料
import companyConstants from '@/constants/company';

export default {
  name: 'EventCavesEducationAuthorize',

  components: { news, satisfaction, youtubeModal, galleryModal },

  data: () => ({
    galleryShow: false,
    material: false,
    isCheckedMore: false,
    headerActive: false,
    materialContent: '',
    materialVideo: 'https://www.youtube.com/embed/2nrvlIn9vFo',
    formUpper: {
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      privacyPolicyCheck: false,
    },
    formLower: {
      parentName: '',
      parentPhone: '',
      parentEmail: '',
      studentChineseName: '',
      studentEnglishName: '',
      studentAge: '',
      privacyPolicyCheck: false,
    },
    level: {
      zero: false,
      one: false,
      three: false,
    },
    // for youtube modal
    youtubeModalUUID,

    // doc modal
    doc: {
      isOpen: false,
      componentText: '',
      content: { title: '', subTitle: '', imgPath: '' },
      contentTwo: { open: false, title: '', subTitle: '', imgPath: '' },
      video: { title: '兒童英文繪本有聲書', vedioPath: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book_trailer' },
    },
    // 再送內容
    docList: [
      { title: '真⼈1對1課程', subTitle: '體驗WUWOW為您打造的英文環境', imgPath: require('@/assets/event/trial/modal-1on1.png') },
      { title: '英⽂能⼒分析', subTitle: '了解你現在的英文程度', imgPath: require('@/assets/event/trial/modal-report.png') }],
    grade: {
      isOpen: false,
      content: { title: '', amount: '', list: [] },
    },
    gradeList: [
      {
        title: '走出英文排斥期', amount: '500', list: ['英語學習啟蒙', '激發英語學習興趣', '建立英語學習信心', '對英文文字的認知'],
      },
      {
        title: '學習以「英文」思考，建立語感', amount: '700~900', list: ['初步發展英語思維能力', '主題式情境學習', '理解並使用簡單句子問答', '解決簡單問題'],
      },
      {
        title: '理解能力成長，表達能力完整', amount: '1400~2500', list: ['累積進階英文詞彙', '英文閱讀素想語技巧養成', '能處理較複雜或抽相的資訊交流', '培養解決綜合任務的能力'],
      },
    ],
    pageMediaSource: '',
    privacyUrl: '/privacy',
    thankYouUrl: '/thank-you',
    // 用來存放 ref 值的流水號
    refCount: {},
    // JR 首頁 預約試聽版面用資料

    // JR 首頁 footer版面用資料
    cooperationUrl: companyConstants.INFO.COOPERATION_URL,
    joinUsUrl: companyConstants.INFO.JOIN_US_URL,
    pmRecruitmentUrl: companyConstants.INFO.PM_RECRUITMENT_URL,
    script: null,
    lfc5Script: null,
  }),

  // for youtube modal
  computed: {
    ...mapState(MODAL, [IS_OPEN]),
    ...mapState(MODAL, [
      PAYLOAD,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][youtubeModalUUID];
    },
    youtubeUrl() {
      return this[PAYLOAD][youtubeModalUUID];
    },
  },

  watch: {
    'doc.isOpen': (isOpen) => {
      // JR 官網有 modal-open 可以用
      const body = document.querySelector('body');
      isOpen ? body.classList.add('modal-open') : body.classList.remove('modal-open');
      // if (!newValue) this.gallery.picIndex = 0;
    },
    'grade.isOpen': (isOpen) => {
      // JR 官網有 modal-open 可以用
      const body = document.querySelector('body');
      isOpen ? body.classList.add('modal-open') : body.classList.remove('modal-open');
      // if (!newValue) this.gallery.picIndex = 0;
    },
  },

  mounted() {
    window.addEventListener('scroll', this.toggleHeaderActive);
    this.bindAnimateElement('[data-scroll]');

    const observerOptions = {
      threshold: 0.1,
      rootMargin: '0% 0% -20% 0%',
    };

    const observer = new IntersectionObserver(this.handleIntersection, observerOptions);
    // 綁定要觸發動畫的元素
    Object.keys(this.$refs).forEach(key => {
      const element = this.$refs[key];
      if (element) {
        observer.observe(element);
      }
    });

    this.initLinePoint();
    // setTimeout(() => {
    //   this.cleanClass('animate-active');
    // }, 80);
  },
  destroyed() {
    window.removeEventListener('scroll', this.toggleHeaderActive);
    this.head.removeChild(this.script);
    this.head.removeChild(this.lfc5Script);
  },

  methods: {
    toggleHeaderActive() {
      if (window.scrollY > 0) {
        this.headerActive = true;
      } else {
        this.headerActive = false;
      }
    },
    toggleCheckMore() {
      const originalScrollPosition = window.scrollY;

      this.isCheckedMore = !this.isCheckedMore;

      document.documentElement.style.scrollBehavior = 'auto';

      this.$nextTick(() => {
        window.scrollTo(0, originalScrollPosition);

        document.documentElement.style.scrollBehavior = '';
      });
    },
    openAbilitiesUpbringingModal() {
      this.doc.componentText = 'content';
      this.doc.content = this.docList[0];
      this.doc.contentTwo = this.docList[1];
      this.doc.contentTwo.open = true;
      this.doc.isOpen = true;
    },
    openVideoBook() {
      this.doc.componentText = 'video';
      this.doc.isOpen = true;
    },
    closeAbilitiesUpbringingModal() {
      this.doc.componentText = '';
      this.doc.contentTwo.open = false;
      this.doc.isOpen = false;
    },
    openMaterialModal(content) {
      this.materialContent = content;
      this.material = true;
    },
    async submitForm(formdata) {
      const fieldChecked = this.fieldCheck(formdata.parentEmail, formdata.parentPhone, formdata.privacyPolicyCheck, formdata);
      if (fieldChecked) {
        const mediaSource = getEventMediaSource(this.$route.path, this.$route.query);
        const noteInfo = this.getNoteInfo(formdata);
        const params = {
          name: formdata.parentName, // 原為學生姓名，改為家長中文姓名
          phone: formdata.parentPhone, // jr丟入家長的電話
          email: formdata.parentEmail, // jr丟入家長的email
          note: noteInfo, // 其餘資訊總和
          media_source: mediaSource,
        };
        const result = await lioshutanApi.common.setMediaSource(params);
        if (result.data.data === 'freecoins_cvq') {
          this.savePhoneNumber(params.phone);
        } else if (result.data.data == null && localStorage.getItem('setFreecoinsCvq')) {
          localStorage.removeItem('setFreecoinsCvq');
        }
        if (result.status === httpCodeConstants.SUCCESS) {
          this.hasLeaveMessage();
          console.log(this.$route.query.m);
          switch (this.$route.query.m) {
            case 'landingpage_fb_ad_eric':
              this.$router.push({ name: 'caves-eric-thank-you' });
              break;
            default:
              this.$router.push({ name: 'caves-thank-you' }); // 通往感謝頁
              break;
          }
        }
      }
    },
    savePhoneNumber(phone) {
      localStorage.setItem('setFreecoinsCvq', true);
      localStorage.setItem('trialPhone', phone);
    },
    hasLeaveMessage() {
      localStorage.setItem('hasWritedPhone', true);
    },

    fieldCheck(email, phone, privacyPolicyCheck, formdata) {
      // let fulfilled = true;
      // Object.values(formdata).forEach((data) => {
      //   if (data === '') {
      //     fulfilled = false;
      //   }
      // });
      // if (!fulfilled) {
      //   alertFailedMessage('似乎還有些欄位尚未填寫完畢');
      //   return false;
      // }

      // 只檢查家長姓名
      if (!formdata.parentName) {
        alertFailedMessage('請留下留下您姓名');
        return false;
      }
      if (!checkEmailFormat(email)) {
        alertFailedMessage('可以再幫我確認一次您的電子郵件帳號嗎');
        return false;
      }
      if (!checkPhoneFormat(phone)) {
        alertFailedMessage('可以再幫我確認一次您的電話號碼嗎');
        return false;
      }
      if (!privacyPolicyCheck) {
        alertFailedMessage('可以再幫我確認一下隱私權政策及學習約定條款嗎');
        return false;
      }
      return true;
    },

    getNoteInfo(formdata) {
      let noteInfo = '';

      const queryGid = this.$route.query.gid;
      if (queryGid) {
        noteInfo += `gid=${queryGid} `;
      }

      // 必填
      if (formdata.parentName) {
        noteInfo += `學員家長姓名: ${formdata.parentName}`;
      }
      // 選填
      if (formdata.studentChineseName) {
        noteInfo += ` | 學員中文姓名: ${formdata.studentChineseName}`;
      }
      if (formdata.studentEnglishName) {
        noteInfo += ` | 學員英文姓名: ${formdata.studentEnglishName}`;
      }
      if (formdata.studentAge) {
        noteInfo += ` | 學員年齡: ${formdata.studentAge}`;
      }
      return noteInfo;
    },

    scrollSection(cssSelector) {
      const formLower = document.querySelector(cssSelector);
      formLower.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    },

    initLinePoint() {
      // Line Point 前置作業
      this.head = document.querySelector('head');
      this.script = document.createElement('script');
      this.script.setAttribute('type', 'text/javascript');
      this.script.innerText = `var freecoins_lpq = [ { "app": "FREECOINS_33485", "expires": 2592000, "domain": ".wuwowjr.tw" } ];`;
      this.lfc5Script = document.createElement('script');
      this.lfc5Script.setAttribute('src', 'https://point-ads.line-apps.com/lfc5.js');
      this.lfc5Script.setAttribute('async', '');
      this.head.appendChild(this.script);
      this.head.appendChild(this.lfc5Script);
    },

    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    // for youtube modal
    youtubeModal(url) {
      this.setIsOpen({
        target: youtubeModalUUID,
        status: !this.youtubeModalIsOpen,
      });

      this.setPayload({
        target: youtubeModalUUID,
        payload: url,
      });
    },

    bindAnimateElement(cssSelector, refPrefix) {
      const animateList = document.querySelectorAll(cssSelector);
      if (!refPrefix) {
        refPrefix = cssSelector.replace(/\[|\]|#/g, '').replace('.', '').replace(/\./g, '-');
      }
      animateList.forEach((element) => {
        const refName = this.generateRefName(this.refCount, refPrefix);
        this.$refs[refName] = element;
      });
    },

    // cleanClass(className) {
    //   const activeList = document.querySelectorAll(`.${className}`);
    //   activeList.forEach((element) => {
    //     element.classList.remove(className);
    //   });
    // },

    generateRefName(refCount, name) {
      if (!refCount[name]) {
        refCount[name] = 1;
        return `${name}-1`;
      } else {
        const count = refCount[name] + 1;
        refCount[name] = count;
        return `${name}-${count}`;
      }
    },

    handleIntersection(entries) {
      // 遍歷Intersection Observer返回的entry列表
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // 如果進入了可視區域，則添加動畫
          // console.log(entry.target);
          entry.target.classList.add('animate-active');
        } else {
          // entry.target.classList.remove('animate-active');
        }
      });
    },
    // for youtube modal
    ...mapMutations(MODAL, [SET_IS_OPEN, SET_PAYLOAD]),
  },
};
</script>
